<template>
  <div class="readme-article">
    <h1 id="招生配置">4.招生配置</h1>
    <h2 id="线索来源渠道">4.1线索来源渠道</h2>
    <p>
      线索来源渠道设置，例如：某某平台、电销、朋友介绍等。
    </p>
    <p>
      <img src="@/assets/img/student/config4-1.png" />
    </p>
    <h2 id="线索意向状态">4.2线索意向状态</h2>
    <p>
      线索意向状态设置，例如：一般、有意向、确报、流失等。
    </p>
    <p>
      <img src="@/assets/img/student/config4-2.png" />
    </p>
    <h2 id="流失原因">4.3流失原因</h2>
    <p>
      流失原因设置，例如：学费太贵、报名其他机构、很忙没时间、专业不对等。
    </p>
    <p>
      <img src="@/assets/img/student/config4-3.png" />
    </p>
    <h2 id="线索登记方式">4.4线索登记方式</h2>
    <p>
      线索登记方式设置，例如：来电、来访、后台、企业合作等。
    </p>
    <p>
      <img src="@/assets/img/student/config4-4.png" />
    </p>
    <h2 id="线索层次-期数">4.5线索层次/期数</h2>
    <p>
      线索层次/期数设置，线索所属层次/期数，添加线索所需选项。
    </p>
    <p>
      <img src="@/assets/img/student/config4-5.png" />
    </p>
    <h2 id="线索名片来源">4.6线索名片来源</h2>
    <p>
      线索名片来源设置，例如：公众号、抖音、快手等。
    </p>
    <p>
      <img src="@/assets/img/student/config4-6.png" />
    </p>
    <h2 id="线索名片商机等级">4.7线索名片商机等级</h2>
    <p>
      线索名片商机等级设置，例如：A、B等。
    </p>
    <p>
      <img src="@/assets/img/student/config4-7.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Student0-4",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>